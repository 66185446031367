<template>
  <div class="page-create-app">
    <top-bar back backroute="selectSub" :params="{id: category.parent?.id}" :title="category.parent?.name || ''" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div>
        <n-form>
          <div class="upload-image-place">
            <c-image-upload :file.sync="file" />
            <span v-if="$var('m1')" class="danger">{{ $var('m1') }}</span>
          </div>
          <n-items>
            <div><t name="7.1" />: {{ category.name }}</div>

<!--            <n-input :placeholder="$app.secure.clean($n.t('7.8'))" v-bind="$form.input('categoryInfo')"></n-input>-->

            <n-input :placeholder="$app.secure.clean($n.t('7.2'))" v-bind="$form.input('volume')"></n-input>

            <n-select class="select-address"
                      :placeholder="$app.secure.clean($n.t('7.3'))"
                      :data="cities" :value.sync="city">
            </n-select>

            <n-input :placeholder="$app.secure.clean($n.t('7.10'))" v-bind="$form.input('address')" />

            <n-input :placeholder="$app.secure.clean($n.t('7.4'))" v-bind="$form.input('name')"></n-input>

<!--            <n-input :placeholder="$app.secure.clean($n.t('7.9'))" v-bind="$form.input('description')"></n-input>-->

            <div class="to-top">
              <!--              <input :checked="toTop" type="checkbox" @click="toTop = !toTop">-->
              <!--              <div class="title"><t name="7.5" /></div>-->
              <!--              <div class="text"><t name="7.6" /></div>-->
            </div>
          </n-items>
        </n-form>
      </div>

      <n-button wide color="yellow" :disabled="Object.keys(city).length === 0" @click="save"><t name="7.7" /></n-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageCreateApp',
  data() {
    return {
      category: {},
      addresses: [],
      cities: [],
      city: {},
      map: null,
      toTop: false,
      fileId: null,
      file: [],
      url: null,
    }
  },
  computed: {
    id() {
      return this.$route.params.categoryId
    },
  },
  watch: {
    file() {
      this.saveFile()
    },
  },
  mounted() {
    ymaps.ready(this.initMap)
  },
  created() {
    this.$form.init({
      volume: '',
      city: {},
      address: '',
      name: '',
    })
    this.$form.rules({
      volume: [ 'required', ],
      city: [ 'required', ],
      address: [ 'required', ],
      name: [ 'required', ],
    })
    this.load()
    this.loadCities()
  },
  methods: {
    loadCities() {
      this.$var('load', true)
      $api.cities.get().with('children').all().then((response) => {
        this.cities = $n.map(response.data.content, (e) => {
          return {
            value: e.id,
            title: e.name,
            children: $n.map(e.children, (c) => {
              return {
                value: c.id,
                title: c.name,
              }
            }),
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    saveFile() {
      if (this.file.length !== 0) {
        this.$var('load', true)
        $api.files.create({ file: this.file, }).then((response) => {
          this.fileId = response.data.data.id
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    load() {
      this.$var('load', true)
      $api.categories.get(this.id).with('parent').then((response) => {
        this.category = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    save() {
      if (this.fileId !== null) {
        this.$var('load', true)
        const data = {
          volume: this.$form.get('volume'),
          address: this.$form.get('address'),
          latitude: this.$form.get('address.coords.0')+'',
          longitude: this.$form.get('address.coords.1')+'',
          name: this.$form.get('name'),
          cityId: this.city.value,
          checkbox: this.toTop,
          categoryId: this.category.id,
          image: this.fileId,
        }
        $api.application.create(data).then(() => {
          this.$router.push({ name: 'index', })
        }).catch((err) => {
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        this.$var('m1', 'Фото обязательно')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.page-create-app {
  .danger {
    margin-top: 10px;
    color: red;
  }
  .to-top{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8C8C8C;
  }
  .map-place {
    display: none;
  }
  .select-address {
    ::v-deep .n-wrapper {
      border: none;
    }
  }
}
</style>
